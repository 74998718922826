import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import SupportForm from "../components/SupportForm";

export default function support() {
  return (
    <Layout>
      <Seo title="Support" />
      <SupportForm />
    </Layout>
  );
}
