import axios from "axios";
import React, { useEffect, useState } from "react";
import SectionTitle from "./SectionTitle";

export default function SupportForm() {
  /* NEW: validation for inputs vvvv */
  const [fieldErrors, setFieldErrors] = useState({});
  const validationRules = {
    email: (val) => val && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val),
    name: (val) => !!val,
    subject: (val) => !!val,
    message: (val) => !!val,
  };
  const validate = () => {
    let errors = {};
    let hasErrors = false;
    for (let key of Object.keys(inputs)) {
      errors[key] = !validationRules[key](inputs[key]);
      hasErrors |= errors[key];
    }
    setFieldErrors((prev) => ({ ...prev, ...errors }));
    return !hasErrors;
  };
  const renderFieldError = (field) => {
    if (fieldErrors[field]) {
      return <p className="text-red-500 mt-2">Please enter a valid {field}.</p>;
    }
  };
  useEffect(() => {
    // Only perform interactive validation after submit
    if (Object.keys(fieldErrors).length > 0) {
      validate();
    }
  }, []);
  /* End new validation ^^^^ */

  // Input Change Handling
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const handleOnChange = (event) => {
    event.persist();
    setInputs((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }));
  };

  // Server State Handling
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      setFieldErrors({});
      setInputs({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    }
  };
  const handleOnSubmit = (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    setServerState({ submitting: true });
    axios({
      method: "POST",
      url: "https://formspree.io/f/xwkazqnp",
      data: inputs,
    })
      .then((r) => {
        handleServerResponse(
          true,
          "We recieved your ticket, our team will get back to you ASAP"
        );
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error);
      });
  };

  return (
    <section className="bg-[#F5F8FF] pt-[170px] pb-28">
      <div className="container">
        <div className="flex">
          <div className="w-full">
            <SectionTitle
              title="Need Help? Open a Ticket"
              paragraph="We will get back to you within 24hours."
              paraWidth="690"
              center
              margin="mb-14"
            />
          </div>
        </div>
        <div className="flex">
          <div className="w-full">
            <div className="bg-white rounded-xl max-w-[970px] w-full mx-auto p-10 sm:py-[60px] sm:px-[65px] md:p-10 lg:py-[60px] lg:px-[65px] shadow-card">
              <form onSubmit={handleOnSubmit} noValidate>
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full lg:w-1/2 px-4">
                    <div className="mb-9">
                      <input
                        id="name"
                        type="text"
                        name="name"
                        onChange={handleOnChange}
                        value={inputs.name}
                        placeholder="Enter your name"
                        className={`w-full bg-white border rounded-md py-4 px-6 placeholder:text-gray-500 focus:border-primary outline-none shadow-card ${
                          fieldErrors.name
                            ? "border-red-500"
                            : "border-transparent"
                        }`}
                      />
                      {renderFieldError("name")}
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 px-4">
                    <div className="mb-9">
                      <input
                        id="company"
                        type="text"
                        name="company"
                        onChange={handleOnChange}
                        placeholder="Company Name (Optional)"
                        value={inputs.company}
                        className={`w-full bg-white border rounded-md py-4 px-6 placeholder:text-gray-500 focus:border-primary outline-none shadow-card ${
                          fieldErrors.company
                            ? "border-red-500"
                            : "border-transparent"
                        }`}
                      />
                      {renderFieldError("company")}
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 px-4">
                    <div className="mb-9">
                      <input
                        id="email"
                        type="email"
                        name="email"
                        onChange={handleOnChange}
                        placeholder="Enter your email"
                        value={inputs.email}
                        className={`w-full bg-white border rounded-md py-4 px-6 placeholder:text-gray-500 focus:border-primary outline-none shadow-card ${
                          fieldErrors.email
                            ? "border-red-500"
                            : "border-transparent"
                        }`}
                      />
                      {renderFieldError("email")}
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 px-4">
                    <div className="mb-9">
                      <input
                        id="subject"
                        type="text"
                        name="subject"
                        onChange={handleOnChange}
                        placeholder="Enter your subject"
                        value={inputs.subject}
                        className={`w-full bg-white border rounded-md py-4 px-6 placeholder:text-gray-500 focus:border-primary outline-none shadow-card ${
                          fieldErrors.subject
                            ? "border-red-500"
                            : "border-transparent"
                        }`}
                      />
                      {renderFieldError("subject")}
                    </div>
                  </div>
                  <div className="w-full px-4">
                    <div className="mb-9">
                      <textarea
                        id="message"
                        name="message"
                        placeholder="Type your message here"
                        rows="6"
                        onChange={handleOnChange}
                        value={inputs.message}
                        className={`w-full bg-white border rounded-md py-4 px-6 resize-none placeholder:text-gray-500 focus:border-primary outline-none shadow-card ${
                          fieldErrors.message
                            ? "border-red-500"
                            : "border-transparent"
                        }`}
                      />
                      {renderFieldError("message")}
                    </div>
                  </div>

                  <div className="w-full px-4 text-center">
                    <button
                      type="submit"
                      className="mx-auto inline-flex items-center justify-center py-4 px-10 bg-primary btn hover:bg-opacity-90"
                      disabled={serverState.submitting}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="w-full px-4">
                    {serverState.status && (
                      <p
                        className={
                          !serverState.status.ok
                            ? "text-red-500"
                            : "text-green-500"
                        }
                      >
                        {serverState.status.msg}
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
